
  import WebChannel from 'models/web_channel.coffee'

  import draggable from 'vuedraggable'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import MenuEditor from '../menu_editor.vue'
  import { H3, Input, KeyListbox, Label, Switch, Textarea } from 'components/generic'

  export default
    props:
      modelValue: WebChannel

    data: ->
      channel: @modelValue
      cookieType: 'noSession'
      languagesDisplay: []

    created: ->
      @cookieType = if @channel.widgetConfig.cookieLifetime == 'session'
        'session'
      else
        'noSession'
      @initLanguageMenu()

    methods:
      initLanguageMenu: ->
        @languagesDisplay = Object.entries(@$root.languages)
          .map ([code, lang]) =>
            code: code
            label: lang
            easyAvailable: @$root.languageVariations[code]?.includes('EASY')
            easyEnabled: @channel.widgetConfig.languageMenu.languageVariations[code]?.includes('EASY')
            display: @channel.widgetConfig.languageMenu.languages.includes(code)
          .sort (a, b) =>
            @channel.widgetConfig.languageMenu.languages.indexOf(b.code) - @channel.widgetConfig.languageMenu.languages.indexOf(a.code)
          .reverse()
        if @channel.widgetConfig.languageMenu.languages.some (code) => !Object.keys(@$root.languages).includes(code)
          @updateLanguages()
      update: ->
        @$emit('update:modelValue', @channel)
      setCookieLifetime: ->
        @channel.widgetConfig.cookieLifetime = if @cookieType == 'session'
          'session'
        else
          WebChannel.DEFAULT_COOKIE_LIFETIME
        @update()
      updateLanguages: ->
        @channel.widgetConfig.languageMenu.languages = @languagesDisplay
          .map (o) -> o.code if o.display
          .compact()
        @channel.widgetConfig.languageMenu.languageVariations = Object.fromEntries(
          @languagesDisplay
            .map (o) -> [o.code, ['DEFAULT', 'EASY']] if o.easyEnabled
            .compact()
        )
        @update()

    components:
      draggable: draggable
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      LanguageSelector: LanguageSelector
      MenuEditor: MenuEditor
      Switch: Switch
      Textarea: Textarea
