
  import Bot from 'models/bot.coffee'
  import ParameterTable from './parameter_table.vue'
  import { Button, DocumentationLink, H3, PageHeader } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot

    computed:
      nonSearchContextParameters: ->
        @$root.contextParameters.filter (cp) -> !cp.contentSearchSchemaKey?
      searchContextParameters: ->
        @$root.contextParameters.filter (cp) -> cp.contentSearchSchemaKey?

    created: ->
      @bot.loadMetaInfo()

    methods:
      createContextParameter: ->
        @$router.push(name: 'newContextParameter')

    components:
      Button: Button
      DocumentationLink: DocumentationLink
      H3: H3
      PageHeader: PageHeader
      ParameterTable: ParameterTable
      PlusIcon: PlusIcon
