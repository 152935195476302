
  import BotbuilderApi from 'models/botbuilder_api'
  import KnowledgeSource from 'models/knowledge_source'
  import Project from 'models/project'
  import TableConfiguration from 'models/table_configuration'
  import { confirm } from 'helpers'
  import { availableLanguages } from 'rails_data'

  import SourceCreatorModal from './source_creator_modal.vue'
  import StatusIcon from './status_icon.vue'
  import { ChevronDownIcon, DocumentTextIcon, GlobeAltIcon, PlusIcon, XIcon } from '@heroicons/vue/outline'
  import { ActionMenu, Api2PaginationWithNumbers, Button, DocumentationLink, EnvironmentBadge, H3, H4, Input, Label, MultiselectDropdown, PageHeader, RadioPanels, Spinner, TableConfigurator } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  export default
    props:
      userSettings: Object

    data: ->
      settings: {}
      filters:
        text: ''
        languages: []
        environments: []
      tableConfig: new TableConfiguration(
        columns: [
          {key: 'description', label: 'Description', defaultShow: true}
          {key: 'canonical', label: 'User-facing label', defaultShow: false}
          {key: 'status', label: 'Status', defaultShow: true}
          {key: 'languages', label: 'Languages', defaultShow: false}
          {key: 'environments', label: 'Environments', defaultShow: false}
          {key: 'docInfo', label: 'Documents info', defaultShow: true}
          {key: 'lastUpdated', label: 'Last updated', defaultShow: true}
        ]
      )
      pollingTimeouts: {}
      debounceTimeout: null
      chatHistoryLength: '1'
      availableLanguages: availableLanguages
      PlusIcon: PlusIcon
      KnowledgeSource: KnowledgeSource
      languageOptions: Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}
      environmentOptions: Project.ENVIRONMENTS
      formalityOptions: [
        {key: 'formal', label: 'formal', description: 'Let the bot answer in a formal way.'}
        {key: 'informal', label: 'informal', description: 'Let the bot answer in an informal way.'}
      ]
      historyOptions: [
        {key: 'on', label: 'yes', description: 'Take the user’s chat history into account when answering questions, e.g. to ask follow-up questions.'}
        {key: 'off', label: 'no', description: 'Do not take the user’s chat history into account when answering questions. This setting may improve the specificity of answers.'}
      ]

    computed:
      filtersForPagination: ->
        text: @filters.text
        languages: @filters.languages.map((lang) -> lang.key).join(',')
        environments: @filters.environments.map((env) -> env.key).join(',')

    created: ->
      @initUserSettings()
      @loadSettings()

    mounted: ->
      @loadSources()

    unmounted: ->
      @clearPollingTimouts()

    methods:
      initUserSettings: ->
        @userSettings.knowledgeSourceList ||= {}
        @userSettings.knowledgeSourceList.columns ||= {}
        @tableConfig.columns.forEach (col) =>
          @tableConfig.columnsByKey[col.key].show = @userSettings.knowledgeSourceList.columns[col.key] if @userSettings.knowledgeSourceList.columns[col.key]?
      loadSources: ->
        @$refs.pagination.load(page: 0)
      loadSettings: ->
        @settings = await BotbuilderApi.getKnowledgeSettings()
        @setChatHistoryLength()
      pollSources: ->
        @clearPollingTimouts()
        @$refs.pagination.items.forEach (source) => @updateAndPoll(source)
      clearPollingTimouts: ->
        Object.values(@pollingTimeouts).forEach (timeout) -> clearTimeout(timeout)
        @pollingTimeouts = {}
      setChatHistoryLength: ->
        @chatHistoryLength = if @settings.chatHistoryLength == 0 then 'off' else 'on'
      updateChatHistoryLength: ->
        @settings.chatHistoryLength = if @chatHistoryLength == 'off' then 0 else 1
        @updateSettings()
      goTo: (source) ->
        @$router.push(name: 'knowledgeSource', params: {sourceId: source.id})
      openCreator: ->
        @$refs.creator.open()
      updateAndPoll: (source, data) ->
        source.updateStatusModel(data)
        return if !source.id
        @pollingTimeouts[source.id] = setTimeout((=> @poll(source)), 3000) if source.inProgress
      poll: (source) ->
        return if !source.id
        BotbuilderApi.getKnowledgeSourceStatus(source.id)
          .then (data) => @updateAndPoll(source, data)
      updateSettings: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> BotbuilderApi.updateKnowledgeSettings(@settings)), 1000)
      saveUserSettings: ->
        @tableConfig.columns.forEach (col) =>
          @userSettings.knowledgeSourceList.columns[col.key] = @tableConfig.columnsByKey[col.key].show
        @$emit('settings-changed')
      actionMenuItems: (source, index) ->
        [
          {label: 'Delete', icon: XIcon, method: => @deleteSource(source, index)}
        ]
      deleteSource: (source, index) ->
        return unless await confirm("Do you really want do delete the knowledge source “#{source.label}”?")
        source.delete()
          .then => @$refs.pagination.remove(index)

    components:
      ActionMenu: ActionMenu
      Api2PaginationWithNumbers: Api2PaginationWithNumbers
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      DocumentationLink: DocumentationLink
      DocumentTextIcon: DocumentTextIcon
      EnvironmentBadge: EnvironmentBadge
      GlobeAltIcon: GlobeAltIcon
      H3: H3
      H4: H4
      Input: Input
      Label: Label
      MultiselectDropdown: MultiselectDropdown
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      RadioPanels: RadioPanels
      SourceCreatorModal: SourceCreatorModal
      Spinner: Spinner
      StatusIcon: StatusIcon
      TableConfigurator: TableConfigurator
