
  import Bot from 'models/bot'
  import BotbuilderApi from 'models/botbuilder_api'
  import DialogModule from 'models/dialog_module'
  import TableConfiguration from 'models/table_configuration'
  import { railsEnv } from 'rails_data'
  import { download } from 'helpers'

  import Navigation from '../navigation.vue'
  import CommandPalette from './command_palette.vue'
  import ModuleTableRow from './module_table_row.vue'
  import ModuleGenerator from './module_generator.vue'
  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Button, DocumentationLink, H3, PageHeader, Table, TableConfigurator, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot
      userSettings: Object

    data: ->
      search: false
      draftModules: []
      activeModules: []
      inactiveModules: []
      tableConfig: new TableConfiguration(
        columns: [
          {key: 'description', label: 'Description', defaultShow: true}
          {key: 'environment', label: 'Environment', defaultShow: false}
        ]
      )
      moduleRowRefs: []
      PlusIcon: PlusIcon

    computed:
      tooManyModules: ->
        Globals.restrictions.dialog_modules < @bot.config.dialogModules.length
      upgradePromptMessage: ->
        "Your subscription plan doesn’t allow to publish a bot with this many modules to live (#{Globals.restrictions.dialog_modules} max)."
      settings: ->
        @userSettings?.moduleList?.bots?[@bot.id]

    watch:
      'bot.config.dialogModules': -> @setModules()
      'bot.config.deletedModules': -> @setModules()

    created: ->
      @initSettings()
      @setModules()
      @bot.loadMetaInfo()

    beforeUpdate: ->
      @moduleRowRefs = []

    methods:
      initSettings: ->
        @userSettings.moduleList ||= {}
        @userSettings.moduleList.bots ||= {}
        @userSettings.moduleList.bots[@bot.id] ||= {}
        @settings.columns ||= {}
        @tableConfig.columns.forEach (col) =>
          @tableConfig.columnsByKey[col.key].show = @settings.columns[col.key] if @settings.columns[col.key]?
      setModules: ->
        @draftModules = @bot.config.dialogModules
          .filter (module) -> module.status == 'DRAFT'
          .sortByString('label')
        @activeModules = @bot.config.dialogModules
          .filter (module) -> module.status == 'ACTIVE'
          .sortByString('label')
        @inactiveModules = @bot.config.dialogModules
          .filter (module) -> module.status == 'INACTIVE'
          .sortByString('label')
        @deletedModules = @bot.config.deletedModules.slice().sortByString('label')
      create: ->
        @bot.config.addDialogModule(type: 'DialogModule', status: 'DRAFT')
        @setModules()
        @$nextTick =>
          @moduleRowRefs[0].edit()
      remove: (index) ->
        @bot.config.removeDialogModuleByIndex(index)
      setDeleted: (module) ->
        @bot.config.addAsDeleted(module)
        @bot.loadMetaInfo()
      downloadConfig: ->
        BotbuilderApi.getConfig(@bot.id, light: false)
          .then (data) =>
            download(
              'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 2)),
              "#{@bot.label}.json"
            )
      goTo: (node) ->
        @$router.push(name: 'module', params: {moduleKey: node.moduleKey}, hash: "##{node.key}")
        @search = false
      addModule: (moduleData) ->
        @bot.config.addDialogModule(moduleData)
      sortingByStatus: (module) ->
        DialogModule.STATUSES.indexOf(module.status)
      saveSettings: ->
        @tableConfig.columns.forEach (col) =>
          @settings.columns[col.key] = @tableConfig.columnsByKey[col.key].show
        @$emit('settings-changed')
      editingRow: (editing) ->
        if editing
          @tableConfig.columnsByKey.description.show = true
        else
          @tableConfig.columnsByKey.description.show = @settings.columns.description
      setModuleRowRef: (index, el) ->
        @moduleRowRefs[index] = el if el?

    components:
      Button: Button
      CommandPalette: CommandPalette
      DocumentationLink: DocumentationLink
      H3: H3
      Navigation: Navigation
      ModuleGenerator: ModuleGenerator
      ModuleTableRow: ModuleTableRow
      PageHeader: PageHeader
      Table: Table
      TableConfigurator: TableConfigurator
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
      UpgradePrompt: UpgradePrompt
