
  import BotConfig from 'models/bot_config.coffee'
  import { confirm } from 'helpers'

  import { ActionMenu, Button, CategoryTableRow, EnvironmentBadge, H1, Input, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'
  import { LightningBoltIcon, PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      config: BotConfig
      items: Array

    data: ->
      query: ''
      rowRefs: {}
      PlusIcon: PlusIcon

    computed:
      filteredContextParameters: ->
        return @items if @query.length == 0
        q = @query.toLowerCase()
        @items.filter (cp) -> cp.label.toLowerCase().startsWith(q)
      parametersByCategory: ->
        categories = @filteredContextParameters
          .map (cp) -> cp.computedCategory.decapitalize()
          .unique()
          .sort()
        ['custom', 'user', 'legal', 'setting'].forEach (category) ->
          index = categories.indexOf(category)
          return if index == -1
          categories.splice(index, 1)
          categories.push(category)
        categories.map (category) =>
          parameters = @filteredContextParameters.filter (cp) -> cp.computedCategory.decapitalize() == category
          key: category
          isPredifined: parameters.every (cp) -> cp.isPredefined
          parameters: parameters.sort()

    created: ->
      @$root.appData.contextParameterList ||= {}
      @$root.appData.contextParameterList.categoriesOpen ||= {}

    beforeUpdate: ->
      @rowRefs = {}

    methods:
      setRowRef: (category, el) ->
        return unless el?
        @rowRefs[category] ||= []
        @rowRefs[category].push(el)
      valueProviderInitiative: (contextParameter) ->
        @config.contextInterface(contextParameter).valueProvider == 'initiative'
      defaultValue: (contextParameter) ->
        @config.contextInterface(contextParameter).defaultValue
      createContextParameterInCategory: (category) ->
        @$router.push(name: 'newContextParameter', query: {category: category})
      remove: (contextParameter) ->
        return unless await confirm("Do you really want to delete context parameter “#{contextParameter.label}”?\nAs a result, the parameter won’t show up in the context parameter list, the bot editor or the inbox anymore. However, it will be preserved in already collected user data that is available in the analytics area.")
        contextParameter.delete()
          .then =>
            @$root.contextParameters = @$root.contextParameters.filter (cp) -> cp.key != contextParameter.key
      actionMenuItems: (contextParameter) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(name:'contextParameter', params: {parameterKey: contextParameter.key})}
          {icon: XIcon, label: 'Delete', method: => @remove(contextParameter)}
        ]
      envInfoText: (contextInterface) ->
        "The current version of this context parameter is on #{contextInterface.environments.listString()}."

    components:
      ActionMenu: ActionMenu
      Button: Button
      CategoryTableRow: CategoryTableRow
      EnvironmentBadge: EnvironmentBadge
      H1: H1
      Input: Input
      LightningBoltIcon: LightningBoltIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
