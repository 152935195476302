
  import DeletedDialogModule from 'models/deleted_dialog_module'
  import DialogModule from 'models/dialog_module'
  import { alert, confirm } from 'helpers'

  import { ActionMenu, Badge, Button, EnvironmentBadge, Input, TableCell, TableRow } from 'components/generic'
  import { BanIcon, CheckIcon, PencilIcon, RefreshIcon, XIcon } from '@heroicons/vue/outline'
  import { ExclamationIcon } from '@heroicons/vue/solid'

  export default
    props:
      module: [DialogModule, DeletedDialogModule]
      columns: Array

    emits: ['remove', 'activated', 'disabled', 'deleted', 'editing']

    data: ->
      copy: @module.clone()
      editing: false

    computed:
      isCoreBehaviour: ->
        @$route.name == 'coreBehaviourList'
      columnsByKey: ->
        Object.fromEntries(
          @columns.map (col) -> [col.key, col]
        )
      actionMenuItems: ->
        return null if @module.status == 'DELETED'
        actionMenuItems = []
        if @module.key? && !@module.isCoreBehavior
          actionMenuItems.push(icon: PencilIcon, label: 'Edit name & description', method: @edit)
          switch @module.status
            when 'DRAFT'
              actionMenuItems.push(icon: CheckIcon, label: 'Activate', method: @activate)
            when 'ACTIVE'
              actionMenuItems.push(icon: BanIcon, iconClass: 'rotate-90', label: 'Disable', method: @deactivate)
            when 'INACTIVE'
              actionMenuItems.push(icon: CheckIcon, label: 'Re-enable', method: @activate)
        if @module.key? && @module.isCoreBehavior
          actionMenuItems.push(icon: RefreshIcon, label: 'Reset', method: @remove)
        else if @module.key?
          actionMenuItems.push(icon: XIcon, label: 'Delete', method: @remove)
        actionMenuItems
      routerTarget: ->
        return null if !@module.key
        return null if @module.status == 'DELETED'
        name: if @isCoreBehaviour then 'coreBehaviour' else 'module'
        params: {moduleKey: @module.key}
      descriptionInProgress: ->
        @module.description.startsWith('<auto-generation::inProgress>')
      descriptionFail: ->
        @module.description.startsWith('<auto-generation::fail>')
      formattedDescription: ->
        @module.description.replace(/<auto-generation::(inProgress|fail)>/, '')
      envWarning: ->
        if ['INACTIVE', 'DELETED'].includes(@module.status) && @module.activeEnvironments.length
          "Still active on #{@module.activeEnvironments.listString()}!"
        else
          null
      envInfoText: ->
        "The current version of this module is active on #{@module.environments.listString()}." +
          if @module.olderVersionActiveEnvironments.length then "\nAn older versions is on #{@module.olderVersionActiveEnvironments.listString()}." else ''

    watch:
      editing: ->
        @$emit('editing', @editing)

    methods:
      goto: ->
        @$router.push(@routerTarget)
      edit: ->
        @editing = true
        @$nextTick => @$refs.labelInput.focus()
      cancel: ->
        if @module.key?
          @copy = @module.clone()
          @editing = false
        else
          @$emit('remove')
      save: ->
        @copy.saveSettings()
          .then (data) =>
            @module.update(data)
            @editing = false
            @copy = @module.clone()
          .catch (response) =>
            # alert is shown, row stays in editing mode
      activate: ->
        @copy.status = 'ACTIVE'
        @save()
          .then => @$emit('activated')
      deactivate: ->
        @copy.status = 'INACTIVE'
        @save()
          .then =>
            @$emit('disabled')
            await alert('The module is now disabled on DEV, but it may still be active on TEST and LIVE until you have promoted your bot.')
      remove: ->
        verb = if @module.isCoreBehavior then 'reset' else 'delete'
        return unless await confirm("Do you really want to #{verb} module “#{@module.label}”?")
        @module.botConfig.deleteDialogModule(@module)
          .then => @$emit('deleted')

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      Button: Button
      EnvironmentBadge: EnvironmentBadge
      ExclamationIcon: ExclamationIcon
      Input: Input
      PencilIcon: PencilIcon
      TableCell: TableCell
      TableRow: TableRow
      XIcon: XIcon
